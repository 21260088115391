<template>
  <Transition name="page" mode="out-in">
    <LoadingPage v-if="isInitialLoading || !auth.user" />
    <PendingApproval v-else-if="!auth.user.workspace" class="text-cyan-800" />
    <div v-else class="flex min-h-screen w-full overflow-hidden bg-gray-50">
      <!-- Sidebar -->
      <div
        class="fixed bottom-0 mt-[1px] flex h-16 w-full sm:static sm:h-[calc(100vh-1px)] sm:w-24 sm:flex-col xl:w-56"
      >
        <NuxtLink
          to="/dashboard"
          aria-label="Home link"
          class="underline-nav -mt-[0.075rem] hidden h-16 min-h-16 w-full items-center justify-center gap-x-2 px-3 text-cyan-800 sm:flex xl:justify-start"
        >
          <Logo class="h-11 w-fit" />
        </NuxtLink>
        <div
          class="flex h-full w-full items-center justify-around gap-x-1 bg-white pl-2 sm:flex-col sm:justify-between sm:gap-x-0 sm:bg-transparent sm:px-3 sm:py-3"
        >
          <div
            class="flex w-full items-center justify-around gap-y-1 sm:flex-col sm:items-start"
          >
            <VerticalTabs :tabs="navItems" />
          </div>
          <div class="flex flex-col gap-y-1 sm:w-full">
            <DropDown v-slot="{ open }" :items="dropDownItems">
              <div
                :class="[
                  'flex w-full items-center justify-center rounded-xl border border-gray-300 px-2 py-1 shadow-sm transition-all hover:bg-gray-200 hover:bg-opacity-50 xl:justify-between',
                  open ? 'bg-gray-200 bg-opacity-50' : ''
                ]"
              >
                <div class="flex items-center gap-x-2">
                  <Avatar class="h-11 w-11" :src="auth.user?.avatar?.url" />
                  <span class="hidden text-sm text-gray-600 xl:inline-block">
                    {{ auth.user.name.split(' ')[0] }}
                  </span>
                </div>
                <Icon
                  name="chevron"
                  :class="[
                    'mr-1 hidden h-3.5 w-3.5 transition-transform xl:flex',
                    open ? 'rotate-180' : ''
                  ]"
                />
              </div>
            </DropDown>
          </div>
        </div>
      </div>
      <!-- Main -->
      <div
        class="h-[calc(100vh-4rem)] w-full flex-1 overflow-y-auto sm:h-screen"
      >
        <NuxtPage />
      </div>
      <LazyPatentUpdates />
    </div>
  </Transition>
</template>

<script setup lang="ts">
// data
useRealtimeSync()
const {
  auth,
  logout,
  query: { isInitialLoading },
  roles
} = useFetchAuth()

const navItems = computed<NavItem[]>(() => {
  return [
    { title: 'Dashboard', to: '/dashboard', icon: 'dashboard' },
    { title: 'Inventions', to: '/inventions', icon: 'light-bulb' },
    { title: 'Research', to: '/research', icon: 'research' }
  ]
})

const dropDownItems = computed<NavItem[]>(() => {
  const navItems: NavItem[] = []
  if (roles.value.has('admin')) {
    navItems.push({
      title: 'Admin',
      icon: 'wrench-screw-driver',
      to: '/admin',
      children: [
        { title: 'Users', icon: 'user', to: '/admin', exact: true },
        { title: 'Workspaces', icon: 'user-group', to: '/admin/workspaces' },
        { title: 'Patent Updates', icon: 'bell', to: '/admin/patent-updates' },
        {
          title: 'Research Corpus',
          icon: 'rectangle-stack',
          to: '/admin/corpus'
        }
      ]
    })
  }

  navItems.push(
    {
      title: 'Settings',
      icon: 'cog',
      to: '/settings/account',
      children: [
        { title: 'Account', icon: 'user', to: '/settings/account' },
        { title: 'Workspace', icon: 'user-group', to: '/settings/workspace' }
      ]
    },
    {
      title: 'Logout',
      icon: 'logout',
      handler: () => logout()
    }
  )
  return navItems
})
</script>
